import React from "react"

import { EditorState, convertFromRaw, convertToRaw } from "draft-js"
import draftToHtml from "draftjs-to-html"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"

const Content = ({ content }) => {
  const editorState = EditorState.createWithContent(
    convertFromRaw(JSON.parse(content))
  )

  const customEntityTransform = (entity, text) => {
    if (entity.type === "IMAGE") {
      const alignment = entity.data.alignment

      if (alignment && alignment.length && alignment !== "none") {
        return '<div style="text-align:'
          .concat(alignment, ';"><img src="')
          .concat(entity.data.src, '" alt="')
          .concat(entity.data.alt, '" style="height: ')
          .concat(entity.data.height, ";width: ")
          .concat(entity.data.width, '"/></div>')
      }
      return '<div style="text-align:center"><img src="'
        .concat(entity.data.src, '" alt="')
        .concat(entity.data.alt, '" style="height: ')
        .concat(entity.data.height, ";width: ")
        .concat(entity.data.width, '"/></div>')
    }
  }

  const createMarkup = () => ({
    __html: draftToHtml(
      convertToRaw(editorState.getCurrentContent()),
      {},
      false,
      customEntityTransform
    ),
  })

  return (
    <div className="row">
      <div
        className="col-12 page-content"
        dangerouslySetInnerHTML={createMarkup()}
      ></div>
    </div>
  )
}

export default Content
